// Import libraries
import React from 'react';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Create component
const NotFoundPage = () => {
    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO title="Niet Gevonden" />
            <Layout>
                <h1>Deze pagina bestaat niet.</h1>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default NotFoundPage;